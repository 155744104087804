<template>
    <div class="flex items-center gap-2">
        <span>{{ applyText }}</span>
        <ArrowTopRightOnSquareIcon
            v-if="applyMethod === 'link'"
            class="h-5 w-5"
        ></ArrowTopRightOnSquareIcon>
        <EnvelopeIcon
            v-if="applyMethod === 'email'"
            class="h-5 w-5"
        ></EnvelopeIcon>
    </div>
</template>

<script setup>
import {
    ArrowTopRightOnSquareIcon,
    EnvelopeIcon,
} from "@heroicons/vue/24/outline/index.js";
import { computed } from "vue";

const props = defineProps({
    applyMethod: {
        type: String,
        default: "link",
    },
});

const applyText = computed(() =>
    props.applyMethod === "email" ? "Apply via Email" : "Apply",
);
</script>
