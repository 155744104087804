<template>
    <template v-if="showModal">
        <Button @click="isOpen = true">
            <ApplyAction :apply-method="applyMethod" />
        </Button>
        <ApplyModal v-model="isOpen" :externalUrl="applyUrl" />
    </template>
    <template v-else>
        <Anchor :href="applyUrl" classMerge="flex">
            <ApplyAction :apply-method="applyMethod" />
        </Anchor>
    </template>
</template>

<script setup>
import ApplyModal from "@/Components/Guest/ApplyModal.vue";
import Button from "@/Components/Guest/Button.vue";
import { ref, computed } from "vue";
import Anchor from "@/Components/Guest/Anchor.vue";
import ApplyAction from "@/Components/Guest/ApplyAction.vue";
import { usePage } from "@inertiajs/vue3";

const page = usePage();
const isOpen = ref(false);
const showModal = ref(!page.props.hideApplyModal);

const props = defineProps({
    applyLink: {
        type: String,
        default: null,
    },
    applyEmail: {
        type: String,
        default: null,
    },
    applyMethod: {
        type: String,
        default: "link",
    },
});

const applyUrl = computed(() =>
    props.applyMethod === "email" ? props.applyEmail : props.applyLink,
);
</script>
